html {
  box-sizing: border-box;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI",
               Roboto, Oxygen-Sans, Ubuntu, Cantarell,
               "Helvetica Neue", sans-serif;
}
*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
/* body {
  padding: 40px;
} */
.header {
  padding: 20px 0;
  text-align: center;
}
.header img {
  max-width: 320px;
}
.header h2 {
  color: #28B372;
  margin: 40px 0 20px 0;
  font-weight: 400;
}
.body form {
  max-width: 450px;
  margin: 0 auto;
}
.body .formField {
  display: flex;
}
.body .formField input {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  flex: 1;
  margin-right: 12px;
  color: #444;
  font-size: 15px;
}
.body .formActions {
  padding: 20px 0;
}
.body .formActions button {
  border: 1px solid #28B372;
  background-color: #28B372;
  color: #fff;
  padding: 12px;
  border-radius: 4px;
  font-size: 15px;
  width: 100%;
}
.close-page-content{
  display: none !important;
}